

function Disclaimer() {

    return (
        <div className="text-page">
            <p className="title-large">Disclaimer</p>
            <strong>Not Investment Advice </strong>
            <p>Investing in stocks and other financial instruments carries risks, and you may lose more than your initial investment. Any opinions, chats, messages, news, research, analyses, prices, or other information contained on this app are provided as general market information for educational and entertainment purposes only and does not constitute investment advice. </p>
            <p>Always conduct independent research before making investment decisions. Market data, opinions, and recommendations may change without notice. Kimi Signals and Maker Design Limited is not responsible for any financial losses or damages resulting from the use of this information. </p>
            <p>We do not recommend making investment decisions based solely on technical analysis or short-term market movements. Past performance is not indicative of future results. </p>
        </div>
    )
}

export default Disclaimer