

function Privacy() {

    return (
        <div className="text-page">
            <p className="title-large">Privacy Policy</p>
            <strong>Last Updated: 13 March, 2025</strong>
            <ol>
                <li>
                    Introduction 
                    <p>Kimi Signals and Maker Design Limited ("we", "us", or "our") respects your privacy and is committed to protecting your personal information. This Privacy Policy explains how we collect, use, and protect the email addresses of users in compliance with New Zealand's privacy laws, including the Privacy Act 2020.</p>
                </li>
                <li>
                    Information We Collect 
                    <p>When you use our app, we may collect the following personal information:</p>
                    <ul>
                        <li>Email address: Provided voluntarily when signing up, subscribing, or contacting us; </li>
                        <li>Instructions from you; </li>
                        <li>Information about how you interact with our services.</li>
                    </ul>
                </li>
                <li>
                    How We Use Your Information 
                    <p>We collect your email address and user information for the following purposes: </p>
                    <ul>
                        <li>Account creation </li>
                        <li>To provide customer support and respond to inquiries. </li>
                        <li>To send important updates, notifications, or promotional materials (only if you have opted in). </li>
                        <li>To improve our services and enhance user experience. </li>
                    </ul>
                </li>
                <li>
                    Data Sharing and Disclosure 
                    <p>We do not sell, rent, or trade your personal information. However, we may disclose your email address in the following situations: </p>
                    <ul>
                        <li>When required by law or government authorities. </li>
                        <li>To service providers assisting in app functionality (e.g., email services), ensuring they comply with privacy obligations. </li>
                    </ul>
                </li>
                <li>
                    Who we can share your information with 
                    <p>We may share your information with third parties, such as: </p>
                    <ul>
                        <li>Subsidiaries of Maker Design Limited; </li>
                        <li>Software-as-a-service providers that we use to provide the service (for example, our customer messaging service provider); </li>
                        <li>Cloud-storage providers; </li>
                        <li>Marketing partners and third party providers (such as Google, Facebook, and programmatic display); </li>
                        <li>Data analytics; </li>
                        <li>Third parties that help us to enhance the safety and security of the Service; </li>
                    </ul>
                </li>
                <li>
                    Data Storage and Security 
                    <p>We implement reasonable security measures to protect your email address from unauthorized access, loss, or misuse. However, no method of transmission over the internet is 100% secure. </p>
                </li>
                <li>
                    Your Rights and Choices 
                    <p>Under the Privacy Act 2020, you have the right to: </p>
                    <ul>
                        <li>Access the personal information we hold about you. </li>
                        <li>Request corrections to any inaccurate or incomplete information. </li>
                        <li>Opt-out of marketing communications by following the unsubscribe instructions in emails. </li>
                    </ul>
                </li>
                <li>
                    Retention of Data 
                    <p>We will retain your email address only as long as necessary to fulfill the purposes outlined in this policy or as required by law. </p>
                </li>
                <li>
                    Third-Party Services 
                    <p>Our app may contain links to third-party websites or services. We are not responsible for their privacy practices, and we encourage you to review their privacy policies before providing any information. </p>
                </li>
                <li>
                    Changes to This Privacy Policy 
                    <p>We may update this Privacy Policy from time to time. Any changes will be posted within the app, and continued use after updates constitutes acceptance of the revised policy. </p>
                </li>
                <li>
                    Contact Us 
                    <p>If you have any questions or concerns about this Privacy Policy or your personal information, please contact us at: </p>
                    <a className="" href="mailto:digital@maker.co.nz">digital@maker.co.nz</a>
                </li>
            </ol>
 
        </div>
    )
}

export default Privacy