import { Button, FormControl, IconButton, InputLabel, MenuItem, Select, TextField, CircularProgress, Collapse, Alert } from "@mui/material"
import { useState } from "react"
import AddPhotoAlternateOutlinedIcon from '@mui/icons-material/AddPhotoAlternateOutlined';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/material/styles';
import { useSearchParams } from "react-router-dom";

const MAX_SIZE = 1024 * 1024 * 16

function Feedback() {
    const [files, setFiles] = useState(null);
    const [submitting, setSubmitting] = useState(false);
    const [successOpen, setSuccessOpen] = useState(false);
    const [failOpen, setFailOpen] = useState(false);

    const [searchParams, ] = useSearchParams();

    const [formValues, setFormValues] = useState(
        {
            name: '',
            type: '',
            email: searchParams.get('email') ?? '',
            feedback: '',
        }
    )

    const handleChange = (e) => {
        setFormValues({...formValues, [e.target.name]: e.target.value})
    }

    const handleFileChange = (e) => {
        if (e.target.files) {
            console.log(e.target.files)
            setFiles([...e.target.files].filter(file => file.type.startsWith('image') && file.size < MAX_SIZE));
        }
    };

    const handleSubmit = async (e) => {
        setSubmitting(true)
        var body = new FormData()
        body.append('form_id', '131')
        body.append('form_token', searchParams.get('form_token'))
        body.append('name', formValues.name)
        body.append('email', searchParams.get('email'))
        body.append('feedback', formValues.feedback)

        if(files && files.length > 0) {
            for(let file of files) {
                body.append('files[]', file)
            }
        }

        fetch('https://formsportal.maker.co.nz/saveformdata', {
            method: 'POST',
            body: body
        }).then((response) => {
            console.log(response)
            setFormValues({
                name: '',
                type: '',
                email: searchParams.get('email'),
                feedback: '',
            })
            setFiles(null)
            setSuccessOpen(true)
        }).catch((error) => {
            console.log(error)
            setFailOpen(true)
        }).finally(() => {
            setSubmitting(false)
        })
    }

    return (
        <div className="feedback-page">
            <div className="text-page">
                <p className="title-large">Feedback</p>
                <p>Please provide your feedback or bug report using the form below. Include screenshots where possible to assist us with your query.</p>
                <div className="feedback-form">
                    <TextField 
                        variant="filled" 
                        label="Name" 
                        name="name" 
                        value={formValues.name} 
                        onChange={handleChange} 
                        required
                    />
                    <TextField 
                        className="pointer-events-none"
                        variant="filled" 
                        label="Email" 
                        name="email" 
                        value={formValues.email}
                        required 
                    />
                    <FormControl variant="filled" required>
                        <InputLabel id="type-label">Feedback Type</InputLabel>
                        <Select variant="filled" labelId="type-label" label="Feedback Type" name="type" value={formValues.type} onChange={handleChange}>
                            <MenuItem value="feedback">Feedback</MenuItem>
                            <MenuItem value="bug">Bug Report</MenuItem>
                        </Select>
                    </FormControl>
                    <TextField variant="filled" label="Comments" name="feedback" value={formValues.feedback} onChange={handleChange} rows={3} multiline required/>
                    <div>
                        <div className="flex items-center gap-2">
                            <Button
                                component="label"
                                role={undefined}
                                variant="outlined"
                                startIcon={<AddPhotoAlternateOutlinedIcon />}
                            >
                                Upload images
                                <VisuallyHiddenInput
                                    type="file"
                                    onChange={handleFileChange}
                                    multiple
                                />
                            </Button>
                            <div>
                                {(files && files.length > 0) 
                                    ? <div>{files.length} files <IconButton size="small" onClick={() => setFiles(null)}><CloseIcon/></IconButton></div> 
                                    : <div>No files uploaded</div> 
                                }
                            </div>
                        </div>
                        <small>Image files only. Max size 16MB per file</small>
                    </div>
                    
                    <Button 
                        className="feedback-form-submit" 
                        variant="contained" 
                        onClick={handleSubmit} 
                        disabled={(!formValues.name || !formValues.email || !formValues.type || !formValues.feedback) || submitting}
                    >
                            {submitting ? <><CircularProgress className="mr-1" color="inherit" size={16}/> Submitting...</> : <>Submit</>}
                    </Button>
                    <div>
                        <Collapse in={successOpen}>
                            <Alert
                                severity="success"
                                action={
                                    <IconButton
                                    aria-label="close"
                                    color="inherit"
                                    size="small"
                                    onClick={() => {
                                        setSuccessOpen(false);
                                    }}
                                    >
                                        <CloseIcon fontSize="inherit" />
                                    </IconButton>
                                }
                            >
                                Your feedback has been submitted
                            </Alert>
                        </Collapse>
                        <Collapse in={failOpen}>
                            <Alert
                                severity="error"
                                action={
                                    <IconButton
                                    aria-label="close"
                                    color="inherit"
                                    size="small"
                                    onClick={() => {
                                        setFailOpen(false);
                                    }}
                                    >
                                        <CloseIcon fontSize="inherit" />
                                    </IconButton>
                                }
                            >
                                An error has occured. Please try again
                            </Alert>
                        </Collapse>
                </div>
                </div>
            </div>
        </div>
    )
}

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});

export default Feedback